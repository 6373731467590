// const RECON_COLUMNS = {
//   INVOICE_NO: 'INVOICE_NO',
//   INVOICE_DT: 'INVOICE_DT',
//   INVOICE_AMT: 'INVOICE_AMT',
//   GST: 'GST',
//   SELLER_GSTIN: 'SELLER_GSTIN',
//   CUSTOMER_GSTIN: 'CUSTOMER_GSTIN'
// };

// const RECON_COLUMNS_TO_LABEL_MAPPING = {
//   [RECON_COLUMNS.INVOICE_NO]: 'Invoice No',
//   [RECON_COLUMNS.INVOICE_DT]: 'Invoice dt',
//   [RECON_COLUMNS.INVOICE_AMT]: 'Invoice Amt',
//   [RECON_COLUMNS.GST]: 'GST',
//   [RECON_COLUMNS.SELLER_GSTIN]: 'Seller GSTIN',
//   [RECON_COLUMNS.CUSTOMER_GSTIN]: 'Customer_GSTIN'
// }

export const COMPARED_COLUMNS = ['Invoice No', 'Invoice dt', 'Invoice Amt', 'GST', 'Seller GSTIN', 'Customer_GSTIN'];

export const MATCHING_EXCLUDED_COLUMNS = ['QR - GST']