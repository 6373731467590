import { COMPARED_COLUMNS, MATCHING_EXCLUDED_COLUMNS } from '../../constants/columns';
import { MATCH_STATUSES } from '../../constants/matchStatuses';

const isColumnComparable = (currentGroupField: string) => MATCHING_EXCLUDED_COLUMNS.indexOf(currentGroupField) === -1

export const getGroupedColumnStatus = (props: any) => {
  const {data, colDef} = props;
  let status = MATCH_STATUSES.MATCHED;

  for (let idx in COMPARED_COLUMNS) {
    const col = COMPARED_COLUMNS[idx];
    const columnGroupKey = colDef.key;
    const gstDataField = `2A - ${col}`;
    const currentGroupField = `${columnGroupKey} - ${col}`;
    if (data && currentGroupField && gstDataField && isColumnComparable(currentGroupField)) {
      if (data[currentGroupField] !== undefined) {
        if (data[gstDataField] !== data[currentGroupField]) {
          status = MATCH_STATUSES.MISMATCH;
          break;
        }
      } else if (data[currentGroupField] === undefined) {
        status = MATCH_STATUSES.MISSING;
        break;
      }
    }
  }
  return status;
}

export const openVendorScoreDashboard = (params: any) => {
  const { rowIndex, node, column } = params;
  const detailGridInfo = {
    id: rowIndex,
    colId: column?.colId
  };
  params.api.addDetailGridInfo(rowIndex, detailGridInfo);
  params.api.getDisplayedRowAtIndex(rowIndex).setExpanded(!node.expanded);
}

export const togglePdfSidePanel = (params: any) => {
  params.context.selectedPdfLink = params.value;
  params.api.openToolPanel('pdfRenderer');
}

const getAmountDiffAndPercent = (columnGroupKey: string, params: any) => {
  const { data } = params;
  let diff = '0';
  let percentage = '0%';
  const colKey = `${columnGroupKey} - Invoice Amt`;
  if (data?.[colKey]) {
    const amount = data?.[colKey];
    const referenceData = data?.[`2A - Invoice Amt`]
    diff = (amount - referenceData).toFixed(2);
    percentage = ((Number(diff) / referenceData) * 100).toFixed(2) + '%';
  }
  return {
    diff,
    percentage
  };
}

export const getInvoiceAmountDiff = (columnGroupKey: string) => (params: any) => {
  const { diff } = getAmountDiffAndPercent(columnGroupKey, params);
  return diff;
}

export const getInvoiceAmountDiffPercent = (columnGroupKey: string) => (params: any) => {
  const { percentage } = getAmountDiffAndPercent(columnGroupKey, params);
  return percentage;
}
