import styles from './email.module.scss';

const EmailPage = (props: any) => {
  return (
    <div className={`email-wrapper w-50 m-auto ${styles.emailWrapper}`}>
      <div className={styles.titleWrapper}>
        <div className="fs-6 mb-4">Add Email Account</div>
        <div className='fw-bold'>POP and IMAP Account Settings</div>
        <div className="mb-4 ps-2">Enter the email settings for your account</div>
      </div>
      <div className={`sub-wrapper ${styles.subWrapper}`}>
        <div className="mb-4">
          <div className="mb-1 fw-bold">User Information</div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">Your Name: </span>
            <input type="text"/></div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">Email Address: </span>
            <input type="email"/>
          </div>
        </div>
        <div className="mb-4">
          <div className="mb-1 fw-bold">Server Information</div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">Account Type: </span>
            <select>
              <option value="IMAP">IMAP</option>
              <option value="POP">POP</option>
            </select>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">Incoming mail server: </span>
            <input type="text"/>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">Outgoing mail server (SMTP): </span>
            <input type="text"/>
          </div>
        </div>
        <div className="mb-4">
          <div className="mb-1 fw-bold">Logon Information</div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">User Name: </span>
            <input type="text"/>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <span className="w-50 mb-3">Password: </span>
            <input type="text"/>
          </div>
        </div>
        <div className={styles.submitBtnWrapper}>
          <button className={styles.submitBtn}>Submit</button>
        </div>
      </div>
    </div>
  )
}

export default EmailPage;