import { useCallback, memo } from "react";
import styles from './textLinkRenderer.module.scss';

type TextLinkRendererPropTypes = {
  value: any,
  label: string,
  handleClick: any,
}

const TextLinkRenderer = (params: TextLinkRendererPropTypes) => {
  const {value, handleClick, label} = params;

  const onLinkClick = useCallback((e: any) => {
    e.stopPropagation();
    handleClick(params);
  }, [handleClick, params])

  return (
    <div onClick={onLinkClick} title={value}>
      <div role="button" className={`link-primary text-truncate ${styles.textLink}`}>{label}</div>
    </div>
  )
}

export default memo(TextLinkRenderer);