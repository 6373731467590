import { useEffect } from "react";
import { Outlet, NavLink, Navigate, Routes, Route } from "react-router-dom";
import { Tooltip } from 'bootstrap';

export default function Root() {
  useEffect(() => {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl, {
          container: 'body',
          trigger : 'hover'
      });
    })
  }, []);

  return (
    <>
      <div id="sidebar" className="d-flex flex-column flex-shrink-0">
       <a href="https://finkraft.ai/" target="_blank" rel="noopener noreferrer" className="logo-wrapper d-block p-3 link-dark text-decoration-none">
          <img src="/finkraft-logo.svg" alt="Finkraft Logo" />
        </a>
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Home">
            <NavLink className="nav-link py-3 border-bottom rounded-0" to={`/home`}>
              <i className="bi-house menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="GST login">
            <NavLink className="nav-link py-3 border-bottom rounded-0" to={`/login`}>
              <i className="bi-briefcase menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Connect email">
            <NavLink className="nav-link py-3 border-bottom rounded-0" to={`/email`}>
              <i className="bi-envelope-at menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="Upload files">
            <NavLink className="nav-link py-3 border-bottom rounded-0" to={`/upload`}>
              <i className="bi-cloud-upload menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item" data-bs-toggle="tooltip" data-bs-placement="right" title="View dashboard">
            <NavLink className="nav-link py-3 border-bottom rounded-0" to={`/dashboard`}>
              <i className="bi-speedometer2 menu-icon"></i>
            </NavLink>
          </li>
        </ul>
      </div>
      <div id="app-content">
        <Routes>
          <Route path="/" element={ <Navigate to="/home" /> } />
        </Routes>
        <Outlet />
      </div>
    </>
  );
}