import {memo, useEffect, useMemo} from 'react';
import styles from './detailCellRenderer.module.scss';
import { COLUMN_TYPE_TO_COMPONENT_FACTORY } from './detailCellRenderer.factory';

const DetailCellRenderer = (props: any) => {
  const { api, node, data } = props;
  const rowIndex = node?.parent?.rowIndex;
  const detailGridInfo = api?.detailGridInfoMap[rowIndex];
  const colId = detailGridInfo?.colId;

  useEffect(() => {
    return () => {
      api.removeDetailGridInfo(rowIndex);
    };
  }, [rowIndex, api]);

  const ComponentToRender = useMemo(() => {
    if (colId) {
      return COLUMN_TYPE_TO_COMPONENT_FACTORY[colId];
    }
    return () => <div className='d-flex justify-content-center align-items-center fs-3'>No Data Found</div>
  }, [colId]);

  return (
    <div className={styles.detailViewContainer}>
      <ComponentToRender data={data} colId={colId} />
    </div>
  )
}

export default memo(DetailCellRenderer);