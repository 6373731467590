import styles from './login.module.scss';

const LoginPage = (props: any) => {
  return (
    <div className={styles.loginWrapper}>
      <div className="col-xs-12">
        <h4>GST Login</h4>
        <hr className="m-0"/>
        <p className="p-0 m-0 d-flex justify-content-end align-items-end"><i className="bi-dot" style={{fontSize: '18px', color: 'red'}}></i>indicates mandatory fields</p>
        <form name="loginform">
          <div className="row mb-4">
            <div className="col-xs-12">
              <label className="m-cir reg">Username<i className="bi-dot" style={{fontSize: '18px', color: 'red'}}></i></label>
              <div className="has-feedback">
                <input id="username" className="form-control pad-r-0" type="text" placeholder="Enter Username" name="user_name"/>
              </div>
            </div>
          </div>
          <div className="d-none">
            <input type="password" name="user_pass"/>
          </div>
          <div className="row mb-4">
            <div className="col-xs-12">
              <label className="m-cir reg">Password<i className="bi-dot" style={{fontSize: '18px', color: 'red'}}></i></label>
              <div className="has-feedback">
                <input id="user_pass" className="form-control" type="password" name="user_pass" placeholder="Enter Password"/>
                <span className="glyphicon glyphicon-ok form-control-feedback"></span>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <button type="submit" className={`btn btn-primary ${styles.loginBtn}`}>LOGIN</button>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start">
              <span className="link">Forgot Username</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-end">
              <span className="link">Forgot Password</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p className="help-block"><i className="fa fa-info-circle"></i><strong> First time login:</strong> If you are logging in for the first time, click <a href="/services/newlogin"><u>here</u></a> to log in.</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginPage;