import { useCallback } from "react";
import styles from './upload.module.scss';

const UploadFiles = (props: any) => {
  const openFileUpload = useCallback(() => {
    const elem = document.getElementById('file-upload');
    elem?.click();
  }, []);
  return (
    <div className={styles.uploadBoxWrapper}>
      <i className="bi-cloud-upload-fill" style={{fontSize: '48px', color: '#2092FF'}}></i>
      <div className="mb-1">Drag and drop files</div>
      <div onClick={openFileUpload} className={styles.uploadLinkText}>Browse your device</div>
      {/* <div className="btn btn-primary text-white">Upload</div> */}
      <input id="file-upload" type="file" hidden />
    </div>
  )
}

export default UploadFiles;