import { memo } from 'react';

type IframeProps = {
  iframeLink: string
}

const Iframe = (props: any) => {
  return (<div style={{height: '100%', width: '100%'}} dangerouslySetInnerHTML={ {__html: props.iframe ? props.iframe : ""}} />);
}

const IframeRenderer = ({iframeLink}: IframeProps) => {
  const iframe = `<iframe height="100%" style="width: 100%;" scrolling="no" title="Iframe" src="${iframeLink}" frameborder="no" allowtransparency="true" allowfullscreen="false"></iframe>`;
  return <Iframe iframe={iframe} />
}

export default memo(IframeRenderer);