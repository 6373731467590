const STATUSES = {
  MATCHED: 'MATCHED',
  MISSING: 'MISSING',
  MISMATCH: 'MISMATCH'
}

export const MATCH_STATUSES = {
  [STATUSES.MATCHED]: 'Matched',
  [STATUSES.MISMATCH]: 'Mismatch',
  [STATUSES.MISSING]: 'Missing'
}