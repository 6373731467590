import { memo, useState, useEffect } from 'react';
import styles from './pdfRenderer.module.scss';

const PdfRenderer = (props: any) => {
  const [pdfLink, setPdfLink] = useState<string>('');
  const { api } = props;

  const onRowClick = (params: any) => {
    setPdfLink(params?.context?.selectedPdfLink);
  }

  useEffect(() => {
    api.addEventListener('rowClicked', onRowClick);
    return () => {
      api.removeEventListener('rowClicked', onRowClick);
    }
  }, [api]);

  return (
    <div className={styles.pdfWrapper}>
      <embed width="100%" height="600" src={pdfLink} type="application/pdf"></embed>
    </div>
  )
}

export default memo(PdfRenderer);