import { useState, useRef, useMemo, useCallback } from 'react';
import styles from './home.module.scss';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { columnDefs } from './home.columns';
import tableData from './demo_data.json';

import DetailCellRenderer from '../../components/cellRenderers/detailCellRenderer';
import PdfRenderer from '../../components/pdfRenderer';

const HomePage = (props: any) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState<any>();

  const defaultColDef = useMemo(()=> ({
    sortable: true,
    filter: true
  }), []);

  const gridContext = useMemo(() => ({
    selectedPdfLink: ''
  }), [])

  const onGridReady = useCallback((params: any) => {
    setRowData(tableData);
    params.api.sizeColumnsToFit();
  }, []);

  const detailCellRenderer = useMemo(() => {
    return DetailCellRenderer;
  }, []);

  const icons = {
    'pdf-file': '<i class="bi-filetype-pdf"></i>',
  };

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: 'pdfRenderer',
          labelDefault: 'PDF View',
          labelKey: 'pdfRenderer',
          iconKey: 'pdf-file',
          width: 500,
          toolPanel: PdfRenderer
        }
      ],
    }),
    []
  );

  return (
    <div className={styles.homePageWrapper}>
      <div className="fs-3 fw-semibold" style={{padding: '20px 20px 0'}}>Reconciliation Report</div>
      <div className="ag-theme-material" style={{width: 'calc(100% - 40px)', height: '100vh', margin: '20px', border: '1px solid #2092FF33'}}>
        <AgGridReact
          context={gridContext}
          ref={gridRef.current} // Ref for accessing Grid's API
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true} 
          rowSelection='multiple'
          enableRangeSelection={true}
          sideBar={sideBar}
          onGridReady={onGridReady}
          detailCellRenderer={detailCellRenderer}
          masterDetail={true}
          detailRowHeight={500}
          icons={icons}
          rowGroupPanelShow={'onlyWhenGrouping'}
        />
      </div>
    </div>
  )
}

export default HomePage;