import MatcherComponent from "../../components/cellRenderers/matcherComponent";
import StatusComponent from "../../components/cellRenderers/statusComponent";
import TextLinkComponent from "../../components/cellRenderers/textLinkRenderer";
import { getGroupedColumnStatus, openVendorScoreDashboard, togglePdfSidePanel, getInvoiceAmountDiff, getInvoiceAmountDiffPercent } from './home.helpers';
import { MATCH_STATUSES } from "../../constants/matchStatuses";

export const columnDefs = [
  {
    headerName: 'Vendor',
    children: [
      { field: 'Vendor - Vendor Name', key: 'Vendor - Vendor Name', cellRenderer: TextLinkComponent, enableRowGroup: true,
        cellRendererParams: (params: any) => {
          return {
            label: params?.value,
            handleClick: openVendorScoreDashboard
          }
        }
      },
      { field: 'Vendor - Score', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'Vendor - Rating', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'Vendor - Category', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'Vendor - % Liabilities Paid', columnGroupShow: 'open', enableRowGroup: true}
    ]
  },
  {
    headerName: 'QR',
    children: [
      { field: 'Match Status', key: 'QR', cellRenderer: StatusComponent, valueGetter: getGroupedColumnStatus, enableRowGroup: true,
        filterParams: {
          values: [
            MATCH_STATUSES.MISMATCH,
            MATCH_STATUSES.MISSING,
            MATCH_STATUSES.MATCHED
          ],
        }
      },
      { field: 'QR - Voucher', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'QR - Detection', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'QR - Decryption', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'QR - Invoice Link', columnGroupShow: 'open', enableRowGroup: true,
        cellRenderer: TextLinkComponent,
        cellRendererParams: () => {
          return {
            label: 'View Invoice',
            handleClick: togglePdfSidePanel
          }
        }
      },
      { field: 'QR - Invoice No', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'QR - Invoice dt', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'QR - Invoice Amt', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: 'QR - Invoice Amt Diff', columnGroupShow: 'open', enableRowGroup: true, valueGetter: getInvoiceAmountDiff('QR')},
      { field: 'QR - Invoice Amt Diff %', columnGroupShow: 'open', enableRowGroup: true, valueGetter: getInvoiceAmountDiffPercent('QR')},
      { field: 'QR - Seller GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'QR - Customer_GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent}
    ]
  },
  {
    headerName: 'OCR',
    children: [
      { field: 'Match Status', key: 'OCR', cellRenderer: StatusComponent, valueGetter: getGroupedColumnStatus, enableRowGroup: true,
        filterParams: {
          values: ['Mismatch', 'Missing', 'Matched'],
        }
      },
      { field: 'OCR - Voucher', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'OCR - Invoice Link', columnGroupShow: 'open', enableRowGroup: true,
        cellRenderer: TextLinkComponent,
        cellRendererParams: () => {
          return {
            label: 'View Invoice',
            handleClick: togglePdfSidePanel
          }
        }
      },
      { field: 'OCR - Invoice No', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'OCR - Invoice dt', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'OCR - Invoice Amt', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: 'OCR - Invoice Amt Diff', columnGroupShow: 'open', enableRowGroup: true, valueGetter: getInvoiceAmountDiff('OCR')},
      { field: 'OCR - Invoice Amt Diff %', columnGroupShow: 'open', enableRowGroup: true, valueGetter: getInvoiceAmountDiffPercent('OCR')},
      { field: 'OCR - GST', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: 'OCR - Seller GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'OCR - Customer_GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent}
    ]
  },
  {
    headerName: 'PR',
    children: [
      { field: 'Match Status', key: 'PR', cellRenderer: StatusComponent, valueGetter: getGroupedColumnStatus, enableRowGroup: true,
        filterParams: {
          values: ['Mismatch', 'Missing', 'Matched'],
        }
      },
      { field: 'PR - Voucher', columnGroupShow: 'open', enableRowGroup: true, },
      { field: 'PR - Invoice No', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'PR - Invoice dt', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'PR - Invoice Amt', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: 'PR - Invoice Amt Diff', columnGroupShow: 'open', enableRowGroup: true, valueGetter: getInvoiceAmountDiff('PR')},
      { field: 'PR - Invoice Amt Diff %', columnGroupShow: 'open', enableRowGroup: true, valueGetter: getInvoiceAmountDiffPercent('PR')},
      { field: 'PR - GST', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: 'PR - Seller GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: 'PR - Customer_GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent}
    ]
  },
  {
    headerName: '2A',
    children: [
      { field: '2A - Invoice No', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: '2A - Invoice dt', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: '2A - Invoice Amt', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: '2A - GST', columnGroupShow: 'open', cellRenderer: MatcherComponent, aggFunc: 'sum'},
      { field: '2A - Seller GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent},
      { field: '2A - Customer_GSTIN', columnGroupShow: 'open', enableRowGroup: true, cellRenderer: MatcherComponent}
    ]
  },
  {
    headerName: 'Eligibility',
    children: [
      { field: 'Eligibility - E-invoicing', enableRowGroup: true},
      { field: 'Eligibility - Vendor Eligibility Date', columnGroupShow: 'open', enableRowGroup: true},
      { field: 'Eligibility - Invoice Eligibility', columnGroupShow: 'open', enableRowGroup: true}
    ]
  },
]
