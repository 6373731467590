import { useEffect, useState } from 'react';
import styles from './messageModal.module.scss';

const MessageModal = (textMsg: string) => {
  const [emailMsg, setEmailMsg] = useState<string>(textMsg);
  useEffect(() => {
    setEmailMsg(textMsg);
  }, [textMsg]);
  return (
    <div className={styles.messageContainer}>
      <div className={styles.headerContainer}>

        <div className="d-flex">
          <button className={`btn btn-primary text-white d-flex flex-column align-items-center`}>
            <i className='bi-send'></i>
            <span>&nbsp;&nbsp;&nbsp;Send&nbsp;&nbsp;&nbsp;</span>
          </button>
        </div>
        <div className="d-flex flex-column" style={{gap: '4px'}}>
          <div className={styles.headingText}><span> To: </span><input type="email"/></div>
          <div className={styles.headingText}><span> Cc: </span><input type="email"/></div>
        </div>
        {/* <button className={`${styles.closeBtn} btn btn-secondary`}><i className="bi-x-lg"></i></button> */}
      </div>
      <textarea rows={10} value={emailMsg} onChange={(e) => setEmailMsg(e.target.value)}></textarea>
    </div>
  )
}

export default MessageModal;